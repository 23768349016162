import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./permission";

import api from "./api";
Vue.prototype.$api = api;

// import "./assets/css/ele.less";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Element, { size: "small", zIndex: 3000 });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");

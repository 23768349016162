import Vue from "vue";
import Router from "vue-router";
import api from "@/api/index";
import { getQueryKw } from "@/utils";

Vue.use(Router);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/login"),
    meta: { title: "登录" },
  },
  {
    path: "/register",
    component: () => import("@/views/register"),
    meta: { title: "注册" },
  },
  {
    path: "/",
    component: () => import("@/views/index"),
    redirect: "/index",
  },
  {
    path: "/index",
    component: () => import("@/views/index"),
    redirect: "/index/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home"),
        meta: { title: "首页" },
      },
      {
        path: "goodsDetail",
        component: () => import("@/views/goodsDetail"),
        meta: { title: "商品详情" },
      },
      {
        path: "allGoods",
        name: "AllGoods",
        // name: "allGoods",
        component: () => import("@/views/allGoods"),
        meta: { title: "全部商品" },
      },
      {
        path: "pointsMall",
        component: () => import("@/views/pointsMall"),
        meta: { title: "积分商城" },
      },
      {
        path: "activity",
        component: () => import("@/views/activity"),
        meta: { title: "活动中心" },
      },
      {
        path: "medicine",
        component: () => import("@/views/medicine"),
        meta: { title: "中药专区" },
      },
      {
        path: "brand",
        component: () => import("@/views/brand"),
        meta: { title: "品牌专区" },
      },
      {
        path: "replace",
        component: () => import("@/views/replace"),
      },
      {
        path: "demand",
        component: () => import("@/views/demand"),
        meta: { title: "组合专区" },
      },
      {
        path: "cart",
        component: () => import("@/views/cart"),
        meta: { title: "购物车" },
      },
      {
        path: "cartCheck",
        name: "CartCheck",
        component: () => import("@/views/cart/check.vue"),
        meta: { title: "核对订单" },
      },
      {
        path: "news",
        name: "News",
        component: () => import("@/views/news"),
        meta: { title: "新闻中心" },
      },
      {
        path: "subRouter",
        component: () => import("@/views/subRouter"),
        children: [
          {
            path: "order",
            component: () => import("@/views/subRouter/orderCenter/order"),
            meta: { title: "所有订单" },
          },
          {
            path: "orderDetail",
            component: () => import("@/views/subRouter/orderCenter/order/orderDetail.vue"),
            meta: { title: "订单详情" },
          },
          {
            path: "returnGoods",
            name: "ReturnGoods",
            component: () => import("@/views/subRouter/orderCenter/returnGoods"),
            meta: { title: "退货申请" },
          },
          {
            path: "baseInfo",
            component: () => import("@/views/subRouter/accountCenter/baseInfo"),
            meta: { title: "基本资料" },
          },
          {
            path: "eleFile",
            component: () => import("@/views/subRouter/accountCenter/eleFile"),
            meta: { title: "电子资质" },
          },
          {
            path: "address",
            component: () => import("@/views/subRouter/accountCenter/address"),
            meta: { title: "收货地址" },
          },
          {
            path: "homepage",
            component: () => import("@/views/subRouter/accountCenter/homepage"),
            meta: { title: "账户首页" },
          },
          {
            path: "multiStore",
            component: () => import("@/views/subRouter/accountCenter/multiStore"),
            meta: { title: "多店管理" },
          },
          {
            path: "bindwx",
            component: () => import("@/views/subRouter/accountCenter/bindwx"),
            meta: { title: "绑定微信" },
          },
          {
            path: "choujiang",
            component: () => import("@/views/subRouter/accountCenter/choujiang"),
            meta: { title: "积分抽奖" },
          },
          {
            path: "coupon",
            component: () => import("@/views/subRouter/wallet/coupon"),
            meta: { title: "优惠券" },
          },
          {
            path: "balance",
            component: () => import("@/views/subRouter/wallet/balance"),
            meta: { title: "资产管理" },
          },
          {
            path: "integral",
            component: () => import("@/views/subRouter/wallet/integral"),
            meta: { title: "积分管理" },
          },
          {
            path: "exchange",
            component: () => import("@/views/subRouter/wallet/exchange"),
            meta: { title: "积分兑换记录" },
          },

          {
            path: "purchase",
            component: () => import("@/views/subRouter/selfService/purchase"),
            meta: { title: "采购计划" },
          },
          {
            path: "collect",
            component: () => import("@/views/subRouter/selfService/collect"),
            meta: { title: "我的收藏" },
          },
          {
            path: "einvoice",
            component: () => import("@/views/subRouter/selfService/einvoice"),
            meta: { title: "电子发票" },
          },
          {
            path: "opinion",
            component: () => import("@/views/subRouter/selfService/opinion"),
            meta: { title: "意见反馈" },
          },
          {
            path: "quick",
            component: () => import("@/views/subRouter/selfService/quick"),
            meta: { title: "快捷采购" },
          },
        ],
      },
    ],
  },
];

const createRouter = () =>
  new Router({
    mode: "history",
    scrollBehavior: () => ({
      y: 0,
    }),
    routes,
  });

const router = createRouter();

router.beforeEach(async (to, from, next) => {
  const { data } = await api("home.getHead");

  document.title = to.meta.title + "-" + data.wzmc || ""; // 从路由元信息获取标题并赋值给document.title
  

  const head = document.getElementsByTagName('head')[0];
   
  const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
  if (existingKeywordsMeta) {
    existingKeywordsMeta.remove(); // 移除已有关键词 meta 标签
  }
  
  const existingDescriptionMeta = document.querySelector('meta[name="description"]');
  if (existingDescriptionMeta) {
    existingDescriptionMeta.remove(); // 移除已有的描述 meta 标签
  }

  // 新增关键词meta标签
  const keywordsMeta = document.createElement('meta');
  keywordsMeta.setAttribute('name', 'keywords');
  keywordsMeta.setAttribute('content', data.gjc);
  head.appendChild(keywordsMeta); 

  //新增描述标签
  const descriptionMeta = document.createElement('meta');
  descriptionMeta.setAttribute('name', 'description');
  descriptionMeta.setAttribute('content', data.subtitle);
  head.appendChild(descriptionMeta);


  //解析 query 值 重新定义title
  let kw = getQueryKw(to.query.value)
  if(kw.title){
    document.title =kw.title
  }
  // console.log(kw)
  // console.log(to.query.value)
  // console.log('路由:',document);
  next();
});

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;

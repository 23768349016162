export default {
  // 首页 -添加网址到桌面
  toDesk: {
    method: "get",
    url: "/down/web/url",
  },
  // 首页 -弹窗
  getPopup: {
    method: "post",
    url: "/home/popup/read",
  },
  // 首页 -文字
  getHead: {
    method: "post",
    url: "/web/head/content",
  },
  // 首页 -点击
  getClick: {
    method: "post",
    url: "/adv/djl/save",
  },
  // 首页 - 菜单,广告图,logo,二维码
  getNavInfo: {
    method: "post",
    url: "/home/index/header",
  },
  // 首页 - 搜索联想词
  getSearch: {
    method: "post",
    url: "/goods/search/list",
  },
  // 首页 - 热门搜索
  getHotkeywords: {
    method: "post",
    url: "/search/hotkeywords/index",
  },
  // 首页 - 商品分类
  getCate: {
    method: "post",
    url: "/goods/cate/list",
  },
  // 首页 - 公告
  getMessage: {
    method: "post",
    url: "/message/message/index",
  },
  // 首页 - 秒杀，优惠券
  getActivity: {
    method: "post",
    url: "/home/activity/list",
  },
  // 首页 - 楼层
  getFloor: {
    method: "post",
    url: "/home/floor/list",
  },
  // 首页 - 企业内容
  getArticle: {
    method: "post",
    url: "/home/article/index",
  },
  // 首页 - 经营资质
  getQualifications: {
    method: "post",
    url: "/home/zzxx/index",
  },
  // 首页 - 品牌专区
  getBrand: {
    method: "post",
    url: "/home/brand/view",
  },
  // 首页 - 底部信息
  getFoot: {
    method: "post",
    url: "/home/footer/list",
  },
};

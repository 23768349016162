import router from "@/router";
import { LgetItem } from "@/utils/storage";

// 定义白名单
const whiteList = ["/login", "/register", "/index/home"];

router.beforeEach((to, from, next) => {
  if (LgetItem("token")) {
    if (to.path === "/login" || to.path === "/register") {
      next("/");
    } else {
      next();
    }
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});

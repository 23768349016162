export default {
  // 积分商城
  getPoints: {
    method: "post",
    url: "/points/prize/index",
  },
  // 积分商城-兑换
  ExchangePoints: {
    method: "post",
    url: "/prize/exchange/save",
  },
  // 活动列表
  getActivity: {
    method: "post",
    url: "/area/promotion/index",
  },
  // 活动列表-优惠券
  getActivityCoupon: {
    method: "post",
    url: "/area/coupon/index",
  },
  // 活动列表-领取优惠券
  receiveActivityCoupon: {
    method: "post",
    url: "/coupon/exchangeone/save",
  },
  // 品牌专区列表
  getBrand: {
    method: "post",
    url: "/area/brand/index",
  },
  // 专区渲染
  getZone: {
    method: "post",
    url: "/home/menu/view",
  },
  // 组合商品列表
  getPackage: {
    method: "post",
    url: "/package/package/index",
  },
  // 组合商品详情
  getPackageDel: {
    method: "post",
    url: "/package/package/read",
  },
  // 组合商品详情
  getMedicine: {
    method: "post",
    url: "/goods/zy/lc",
  },
};

export default {
  // 商品列表
  getGoods: {
    method: "post",
    url: "/goods/goods/index",
  },
  // 商品列表-搜索条件
  getGoodsFilter: {
    method: "post",
    url: "/goods/filter/read",
  },
  // 商品列表-商品搜索 - 厂家
  getGoodsFilterscqy: {
    method: "post",
    url: "/goods/filter/scqy",
  },
  // 商品列表-商品搜索 - 规格
  getGoodsFiltersku: {
    method: "post",
    url: "/goods/filter/sku",
  },
  // 商品详情
  getGoodsDel: {
    method: "post",
    url: "/goods/goods/read",
  },
  // 商品-加入购物车
  addCart: {
    method: "post",
    url: "/shoppingcart/goods/save",
  },
  // 商品-添加收藏
  addCollect: {
    method: "post",
    url: "/collect/goods/save",
  },
  // 商品详情 - 同类热销
  getHotGoods: {
    method: "post",
    url: "/goods/goods/index",
  },
};

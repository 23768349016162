export default {
  // 登录被挤
  getisLogin: {
    method: "post",
    url: "/mine/login/hs",
  },
  // 账户信息
  getMyInfo: {
    method: "post",
    url: "/mine/mine/read",
  },
  // 我的客服
  getCustomer: {
    method: "post",
    url: "/service/service/read",
  },
  // 订单列表
  getOrder: {
    method: "post",
    url: "/order/order/index",
  },
  // 订单列表-时间
  getOrderTime: {
    method: "post",
    url: "/order/search/time",
  },
  // 订单详情
  getOrderDel: {
    method: "post",
    url: "/order/order/read",
  },
  // 订单-待付款 - 取消订单
  cancelOrder: {
    method: "post",
    url: "/order/cancel/save",
  },
  // 订单-待收货 - 确认收货
  deliveryOrder: {
    method: "post",
    url: "/order/receipt/save",
  },
  // 订单-再次购买
  buyTwice: {
    method: "post",
    url: "/order/repurchase/save",
  },
  // 退货单列表
  getReturn: {
    method: "post",
    url: "/order/return/index",
  },
  // 退货单详情
  getReturnDel: {
    method: "post",
    url: "/order/return/read",
  },
  // 申请退货
  applyReturn: {
    method: "post",
    url: "/order/aftersales/save",
  },
  // 获取退货原因
  getReturnReason: {
    method: "post",
    url: "/order/reason/index",
  },
  // 资质证件
  getCertificate: {
    method: "post",
    url: "/certificate/certificate/read",
  },
  // 资质证件修改
  updateCertificate: {
    method: "post",
    url: "/certificate/certificate/save",
  },
  // 收藏列表
  getCollect: {
    method: "post",
    url: "/collect/goods/index",
  },
  // 收藏 - 删除
  delCollect: {
    method: "post",
    url: "/collect/goods/delete",
  },
  // 我的投诉
  getOpinion: {
    method: "post",
    url: "/member/tsxx/index",
  },
  // 投诉类型
  getOpinionType: {
    method: "post",
    url: "/member/tsxx/type",
  },
  // 上传投诉
  updateOpinion: {
    method: "post",
    url: "/member/tsxx/save",
  },
  // 采购需求列表
  getDemands: {
    method: "post",
    url: "/demands/demands/index",
  },
  // 采购需求添加
  addDemands: {
    method: "post",
    url: "/demands/demands/save",
  },
  // 采购需求详情
  getDemandsDel: {
    method: "post",
    url: "/demands/demands/read",
  },
  // 我的优惠券
  getCoupon: {
    method: "post",
    url: "/mine/coupon/index",
  },
  // 电子发票
  getEinvoice: {
    method: "post",
    url: "/invoice/invoice/index",
  },
  // 电子发票-详情
  einvoiceDel: {
    method: "post",
    url: "/invoice/invoice/read",
  },
  // 积分流水
  getPoints: {
    method: "post",
    url: "/points/record/index",
  },
  // 积分类型 + 统计
  getPointsType: {
    method: "post",
    url: "/points/record/type",
  },
  // 积分兑换记录
  getPointsRecord: {
    method: "post",
    url: "/prize/dhjl/list",
  },
  // 积分兑换记录-发放状态
  getPointsffzt: {
    method: "post",
    url: "/prize/ffzt/list",
  },
  // 绑定账号列表
  getAccountList: {
    method: "post",
    url: "/account/account/index",
  },
  // 绑定账号
  bindAccount: {
    method: "post",
    url: "/account/account/save",
  },
  // 切换用户
  switchAccount: {
    method: "post",
    url: "/session/change/save",
  },
  // 删除关联账户
  delAccount: {
    method: "post",
    url: "/account/account/delete",
  },
  // 收货地址
  getAddress: {
    method: "post",
    url: "/address/address/read",
  },
  // 会员基本信息获取
  getInfo: {
    method: "post",
    url: "/member/attr/read",
  },
  // 商品经营权限
  getManage: {
    method: "post",
    url: "/goods/jyqx/list",
  },
  // 会员基本信息-修改
  updateInfo: {
    method: "post",
    url: "/member/attr/save",
  },
  // 余额提现列表
  getBalance: {
    method: "post",
    url: "/return/balance/index",
  },
  // 发起余额提现
  fetchBalance: {
    method: "post",
    url: "/return/balance/save",
  },

  // 余额管理属性
  getBillAttribute: {
    method: "post",
    url: "/bill/bill/cwlx",
  },
  // 余额管理列表
  getBill: {
    method: "post",
    url: "/bill/bill/index",
  },
  
  // 是否签到
  signread: {
    method: "post",
    url: "/mine/sign/read",
  },
  // 点击签到
  signsave: {
    method: "post",
    url: "/mine/sign/save",
  },

  // 获取抽奖背景图
  luckdrawluckdrawread: {
    method: "post",
    url: "/luckdraw/luckdraw/read",
  },
  //点击抽奖
  luckdrawdrawsave:{
    method: "post",
    url: "/luckdraw/draw/save",
  },

  //获取奖品图片和剩余的抽奖次数
  luckdrawtimesread:{
    method: "post",
    url: "/luckdraw/times/read",
  },

  //获取弹窗数据
  luckdrawprizeindex:{
    method: "post",
    url: "/luckdraw/prize/index",
  }
};





export default {
  // 新闻页面列表
  getNews: {
    method: "post",
    url: "/web/new/list",
  },
  // 新闻页面详情
  getNewsDel: {
    method: "post",
    url: "/web/new/view",
  },
};

import router from "@/router";
import { MessageBox, Message } from "element-ui";
import api from "@/api";
import { LgetItem, LreItem } from "../utils/storage";



/**
 * 解析 query 值
 */
export function getQueryKw(kw){
  let list = {};

  if(kw){
    let queryVal = kw
    queryVal = queryVal.replace("?", "")
    let queryValArr =queryVal.split("&");
  
    queryValArr.forEach(item=>{
      let arr = item.split("=");
      list[arr[0]]=arr[1]
  
    })
  }
  
  return list;
  
}


/**
 * 
 * @param {*} r ：图片
 * 弹出层图片超出可视区域75%
 * 按比例裁剪重新赋值宽高
 */
export function loadImage(r){

  let img = r.target
  if(img.src != ''){
    let imgsize = imgTrim(img.src);
    img.style.width = `${imgsize.w}px`;
    img.style.height = `${imgsize.h}px`;
  }
  
}


export function imgTrim(img) {
    let max_w = Math.floor(window.innerWidth * 0.50)
    let max_h = Math.floor(window.innerHeight * 0.50)
    
    let imgval = new Image()
    imgval.src = img
    
    let img_w= imgval.width
    let img_h= imgval.height


    let dis_w = Number(imgval.width/max_w).toFixed(2)
    let dis_h = Number(imgval.height/max_h).toFixed(2)
    
    let cj_w = img_w
    let cj_h = img_h

    if(dis_w>1 || dis_h>1){
      if(dis_w > dis_h){
        cj_w = Math.floor(img_w/dis_w)
        cj_h = Math.floor(img_h/dis_w)
      }else{
        cj_w = Math.floor(img_w/dis_h)
        cj_h = Math.floor(img_h/dis_h)
      }
    }

    let data = {}
    data.w = cj_w
    data.h = cj_h
    return data;
}


//封装跳转
export function handleJump(jump) {
  console.log(jump, "jump");
  if (jump.type == 101 && jump.url) {
    if (jump.url == "/index/goodsDetail") {
      //商品详情页打开新标签
      let routeUrl = router.resolve({
        path: jump.url,
        query: { value: jump.value },
      });
      window.open(routeUrl.href, "_blank");
    } else {
      router.push({
        path: jump.url,
        query: { value: jump.value },
      });
    }
  }
}

export function getZoneInfo(value) {
  return new Promise((resolve, reject) => {
    let index = value.indexOf("menu_id");
    let result = value.substr(index + 8, value.length);

    api("zone.getZone", { menu_id: result })
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        reject(false);
      });
  });
}

export function handleData(requestapi, params, message) {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(`是否${message}?`, "温馨提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const res = await api(requestapi, params);
        if (!res) return reject(false);
        Message({
          type: "success",
          message: `${message}成功!`,
        });
        resolve(true);
      })
      .catch(() => {});
  });
}

export function isLogin() {
  if (LgetItem("token")) return true;
  else return false;
}

export function backToLogin() {
  return new Promise((resolve, reject) => {
    if (LgetItem("token")) resolve(true);
    else {
      Message({
        type: "error",
        message: "请先登录",
      });
      setTimeout(() => {
        router.replace("/login");
        LreItem("token");
        LreItem("info");
      }, 1000);
      reject();
    }
  });
}
